@import '../../styles/variables';

.crumb__container {
  .crumb__paper {
    padding: 0;
    background-color: inherit;

    .breadcrumb {
      display: flex;
      text-decoration: none;
      color: $off-black;

      &--disabled {
        pointer-events: none;
        color: inherit;
      }

      .icon {
        margin-right: 4px;
        width: 20px;
        height: 20px;
      }
    }
  }
}
