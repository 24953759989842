//basics
html {
  min-height: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-size: $base-font-size;
  font-family: $font-stack-primary;
  font-weight: normal;
  color: $primary-text;
  background-color: $primary-bg-color;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  min-height: 100%;
  margin: 0;
  padding: 0;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

input[type='text']::-ms-clear {
  display: none;
}

.spinner {
  display: block;
  margin: 2em auto;
  width: 2em;
  height: 2em;
  background-image: $spinner-b64-url;
  background-size: contain;
  background-repeat: no-repeat;
}

//temp stuff
.pf-main h2,
.pf-main p {
  margin-left: 2rem;
}
