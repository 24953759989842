//mixins

@mixin flexbox-center {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}

@function px-to-unit($pxval, $unit) {
  @return ($pxval / $base-font-size) + $unit;
}

@mixin visually-hidden {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}
