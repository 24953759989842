@import '../variables';

.btn {
  border-radius: 2px;
  font-family: $font-stack-primary;
  font-weight: 600;
  text-transform: capitalize;

  &--small {
    padding: 0.3em 0.5em;
    font-size: 0.9em;
    min-height: 30px;
  }

  &--medium {
    padding: 0.8em;
    font-size: 1em;
    min-height: 40px;
  }

  &--large {
    padding: 1em;
    font-size: 1em;
    min-height: 50px;
  }

  &--white {
    background-color: #fff;
  }

  &--primary {
    background-color: $orange;
    color: $off-black;

    &:disabled {
      background-color: lighten($orange, 30%);
      color: $grey-low-mid;
    }

    &:hover {
      background-color: lighten($orange, 10%);
      color: $off-black;
    }
  }

  &--secondary {
    background-color: $grey-high-mid;
    color: $off-white;

    &:disabled,
    &.disabled {
      background-color: lighten($grey-high-mid, 30%);
      color: $grey-low-mid;
    }

    &:hover {
      &:not(.disabled) {
        background-color: lighten($grey-high-mid, 10%);
        color: $off-white;
      }
    }
  }

  &__error--primary {
    background-color: $error-primary;
    color: $off-white;
    &:hover {
      background-color: lighten($error-primary, 10%);
      color: $off-white;
    }
  }

  &__error--secondary {
    background-color: $error-secondary;
    color: $off-white;

    &:hover {
      background-color: lighten($error-primary, 10%);
      color: $off-white;
    }
  }
}
