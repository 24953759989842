@import '../variables';

.pad {
  &--0 {
    padding: 0;
  }
  &--8 {
    padding: $pad-8;
  }
  &--12 {
    padding: $pad-12;
  }
  &--16 {
    padding: $pad-16;
  }
  &--24 {
    padding: $pad-24;
  }

  &__top {
    &--0 {
      padding-top: 0;
    }
    &--8 {
      padding-top: $pad-8;
    }
    &--12 {
      padding-top: $pad-12;
    }
    &--16 {
      padding-top: $pad-16;
    }
    &--24 {
      padding-top: $pad-24;
    }
  }

  &__right {
    &--0 {
      padding-right: 0;
    }
    &--8 {
      padding-right: $pad-8;
    }
    &--12 {
      padding-right: $pad-12;
    }
    &--16 {
      padding-right: $pad-16;
    }
    &--24 {
      padding-right: $pad-24;
    }
  }

  &__bottom {
    &--0 {
      padding-bottom: 0;
    }
    &--8 {
      padding-bottom: $pad-8;
    }
    &--12 {
      padding-bottom: $pad-12;
    }
    &--16 {
      padding-bottom: $pad-16;
    }
    &--24 {
      padding-bottom: $pad-24;
    }
  }
  &__left {
    &--0 {
      padding-left: 0;
    }
    &--8 {
      padding-left: $pad-8;
    }
    &--12 {
      padding-left: $pad-12;
    }
    &--16 {
      padding-left: $pad-16;
    }
    &--24 {
      padding-left: $pad-24;
    }
  }
}

.margin {
  &--0 {
    margin: 0;
  }
  &--8 {
    margin: $pad-8;
  }
  &--12 {
    margin: $pad-12;
  }
  &--16 {
    margin: $pad-16;
  }
  &--24 {
    margin: $pad-24;
  }

  &__top {
    &--0 {
      margin-top: 0;
    }
    &--8 {
      margin-top: $pad-8;
    }
    &--12 {
      margin-top: $pad-12;
    }
    &--16 {
      margin-top: $pad-16;
    }
    &--24 {
      margin-top: $pad-24;
    }
  }
  &__right {
    &--0 {
      margin-right: 0;
    }
    &--8 {
      margin-right: $pad-8;
    }
    &--12 {
      margin-right: $pad-12;
    }
    &--16 {
      margin-right: $pad-16;
    }
    &--24 {
      margin-right: $pad-24;
    }
  }
  &__bottom {
    &--0 {
      margin-bottom: 0;
    }
    &--8 {
      margin-bottom: $pad-8;
    }
    &--12 {
      margin-bottom: $pad-12;
    }
    &--16 {
      margin-bottom: $pad-16;
    }
    &--24 {
      margin-bottom: $pad-24;
    }
  }
  &__left {
    &--0 {
      margin-left: 0;
    }
    &--8 {
      margin-left: $pad-8;
    }
    &--12 {
      margin-left: $pad-12;
    }
    &--16 {
      margin-left: $pad-16;
    }
    &--24 {
      margin-left: $pad-24;
    }
  }
}

.border {
  &__circle {
    border-radius: 100%;
  }
}

.border {
  &__top {
    border-top: 1px solid $grey-high-light;
  }
  &__right {
    border-right: 1px solid $grey-high-light;
  }
  &__bottom {
    border-bottom: 1px solid $grey-high-light;
  }
  &__left {
    border-left: 1px solid $grey-high-light;
  }
  &__all {
    border: 1px solid $grey-high-light;
  }
}

.full-width {
  width: 100%;
}
